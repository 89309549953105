import { ElementType, ForwardedRef } from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { ButtonUseCase } from "types";

import { ButtonColorMap } from "../constants";

export type SecondaryButtonProps<
  D extends ElementType = "button",
  // eslint-disable-next-line @typescript-eslint/ban-types
  P = {}
> = ButtonProps<D, P> & {
  component?: D;
  size?: "medium";
  useCase?: Extract<ButtonUseCase, "main" | "destructive">;
  forwardedRef?: ForwardedRef<D>;
  loading?: boolean;
  loadingText?: string;
};

const useStyles = makeStyles()((theme) => ({
  button: {
    backgroundColor: theme.palette.background.default,
  },
  sizeMedium: {
    ...theme.typography.textMediumRegular,
    padding: theme.spacing(1, 2),
  },
  main: {
    color: theme.palette.primary.main,
    border: theme.mixins?.borderStyle1?.border,
    "&:hover:not([disabled]), &:focus, &.Mui-focusVisible": {
      border: theme.mixins?.borderStyle1?.border,
      background: theme.palette.primary.light,
    },
    "&:disabled": {
      backgroundColor: theme.palette.background.default,
      borderColor: theme?.colors?.["secondary-disabled-border"],
      color: theme?.colors?.["secondary-disabled-text"],
    },
    "&.loading": {
      "&:disabled": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
        border: theme.mixins?.borderStyle1?.border,
      },
    },
  },
  loadingBuffer: {
    marginLeft: theme.spacing(1), // Spacing for the loading spinner
  },
  destructive: {
    border: `1px solid ${theme?.colors?.["danger-main"]}`,
    "&:hover, &:focus, &.Mui-focusVisible": {
      border: `1px solid ${theme?.colors?.["danger-main"]}`,
      background: theme.palette.secondary.light,
    },
    "&.loading": {
      "&:disabled": {
        color: theme?.colors?.["danger-main"],
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme?.colors?.["danger-main"]}`,
      },
    },
  },
}));

/**
 * Secondary Buttons are a combination of Use Case (Main and Destructive) and Action Size (Only M)). They most closely correspond to the "Outlined" style in Material-UI
 */
export const SecondaryButton = <
  D extends ElementType = "button",
  // eslint-disable-next-line @typescript-eslint/ban-types
  P = {}
>({
  size = "medium",
  useCase = "main",
  color,
  children,
  forwardedRef,
  classes: classesProp,
  loading = false,
  loadingText = "Loading...",
  disabled,
  ...props
}: SecondaryButtonProps<D, P>) => {
  const { classes, cx } = useStyles();

  return (
    <Button
      ref={forwardedRef as ForwardedRef<HTMLButtonElement>}
      disableFocusRipple
      color={ButtonColorMap[useCase]}
      disabled={disabled || loading}
      size={size === "medium" ? "small" : undefined}
      variant="outlined" //Our "medium" font style refers to "small" in material ui sizing
      classes={{
        ...classesProp,
        root: cx(classes.button, classesProp?.root),
        outlinedPrimary: cx(classes.main, classesProp?.outlinedPrimary),
        outlinedSecondary: cx(
          classes.destructive,
          classesProp?.outlinedSecondary
        ),
        outlinedSizeSmall: cx(
          classes.sizeMedium,
          classesProp?.outlinedSizeSmall
        ),
        disabled: cx({ loading: loading }),
      }}
      {...props}
    >
      {loading ? (
        <>
          <CircularProgress color="inherit" size="1rem"></CircularProgress>
          <span className={classes.loadingBuffer}>{loadingText}</span>
        </>
      ) : (
        children
      )}
    </Button>
  );
};
