import React, { CSSProperties, useCallback, useState } from "react";
import {
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
// eslint-disable-next-line import/no-named-as-default
import CopyToClipboard from "react-copy-to-clipboard";

import { CopyIcon } from "./icons";

const COPY = "Copy";
const COPIED = "Copied to Clipboard!";

export interface CopyToClipboardButtonProps extends CopyToClipboard.Props {
  customSize?: number | string;
  customPadding?: CSSProperties["padding"];
  clickToCopyTooltip?: string;
  copiedTooltip?: string;
  TooltipProps?: Partial<TooltipProps>;
  onCopyClick?: (e: React.MouseEvent) => void;
  iconColor?: IconButtonProps["color"];
}

const useStyles = makeStyles<{ customPadding: number | string }>()(
  (theme, { customPadding }) => ({
    icon: {
      padding: customPadding,
      "&:hover": {
        backgroundColor: theme.colors["primary-focus"],
      },
    },
  })
);

export const CopyToClipboardButton = ({
  text,
  onCopy,
  customSize = 16,
  customPadding = 8,
  clickToCopyTooltip = COPY,
  copiedTooltip = COPIED,
  TooltipProps,
  onCopyClick,
  iconColor = "primary",
}: CopyToClipboardButtonProps) => {
  const { classes } = useStyles({ customPadding });
  const [tooltip, setTooltip] = useState<string>(clickToCopyTooltip);

  const handleCopy = useCallback(
    (copiedText: string, didSucceed: boolean) => {
      setTooltip(copiedTooltip);
      onCopy?.(copiedText, didSucceed);
    },
    [onCopy, copiedTooltip]
  );

  const handleCopyClick = useCallback(
    (e: React.MouseEvent) => {
      onCopyClick?.(e);
    },
    [onCopyClick]
  );

  return (
    <span onMouseLeave={() => setTooltip(clickToCopyTooltip)}>
      <CopyToClipboard text={text} onCopy={handleCopy}>
        <Tooltip
          key={`${text} tooltip`}
          placement="top"
          title={tooltip}
          {...TooltipProps}
          onClick={handleCopyClick}
        >
          <IconButton className={classes.icon} color={iconColor} size="large">
            <CopyIcon height={customSize} width={customSize} />
          </IconButton>
        </Tooltip>
      </CopyToClipboard>
    </span>
  );
};
