import { ElementType, ForwardedRef } from "react";
import { Button, ButtonProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { ButtonUseCase } from "types";

import { ButtonColorMap } from "../constants";

export type TertiaryButtonProps<
  D extends ElementType = "button",
  // eslint-disable-next-line @typescript-eslint/ban-types
  P = {}
> = ButtonProps<D, P> & {
  component?: D;
  size?: "small" | "medium";
  useCase?: Extract<ButtonUseCase, "main" | "destructive">;
  forwardedRef?: ForwardedRef<D>;
};

const useStyles = makeStyles()((theme) => ({
  sizeSmall: {
    ...theme.typography.textSmallRegular,
    padding: theme.spacing(0.5, 1),
  },
  sizeMedium: {
    ...theme.typography.textMediumRegular,
    padding: theme.spacing(1, 2),
  },
  main: {
    color: theme.palette.primary.main,
    "&:hover:not([disabled]), &:focus, &.Mui-focusVisible": {
      background: theme.palette.primary.light,
      textDecoration: "underline",
    },
  },
  destructive: {
    "&:hover:not([disabled]), &:focus, &.Mui-focusVisible": {
      background: theme.palette.secondary.light,
      textDecoration: "underline",
    },
  },
}));

/**
 * Tertiary Buttons are a combination of Use Case (Main and Destructive) and Action Size (S and M). They most closely correspond to the "Text" style in Material-UI
 */
export const TertiaryButton = <
  D extends ElementType = "button",
  // eslint-disable-next-line @typescript-eslint/ban-types
  P = {}
>({
  useCase = "main",
  size = "medium",
  color,
  children,
  forwardedRef,
  classes: classesProp,
  ...props
}: TertiaryButtonProps<D, P>) => {
  const { classes, cx } = useStyles();

  return (
    <Button
      ref={forwardedRef as ForwardedRef<HTMLButtonElement>}
      disableFocusRipple
      color={ButtonColorMap[useCase]}
      size={size === "medium" ? "large" : "small"}
      variant="text" //Our "medium" font style refers to "large" in material ui sizing
      classes={{
        ...classesProp,
        textPrimary: cx(classes.main, classesProp?.textPrimary),
        textSecondary: cx(classes.destructive, classesProp?.textSecondary),
        textSizeSmall: cx(classes.sizeSmall, classesProp?.textSizeSmall),
        textSizeLarge: cx(classes.sizeMedium, classesProp?.textSizeLarge),
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
