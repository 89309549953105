import { ReactElement, SyntheticEvent } from "react";
import {
  Alert,
  AlertProps,
  Slide,
  Snackbar,
  SnackbarProps,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { CheckCircleIcon, TimesCircleErrorIcon } from "./icons";
import { SecondaryButton } from "./SecondaryButton";
import { TertiaryButton } from "./TertiaryButton";

const noop = () => {};

const useStyles = makeStyles()((theme) => ({
  snackbar: {
    maxWidth: "592px",
    width: `calc(100% - ${theme.spacing(2)})`, // MUI gives 8px horizontal spacing
    wordBreak: "break-word",
  },
  alert: {
    width: "100%",
    background: theme.palette.background.default,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.5)",
    borderRadius: "0px",
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    ...theme.typography.textMediumRegular,
  },
  action: {
    // Stack the buttons for mobile / tablet
    [theme.breakpoints.down("lg")]: {
      alignItems: "flex-end",
      flexDirection: "column",
    },
  },
  message: {
    padding: 0,
    alignSelf: "center",
  },
  icon: {
    marginBottom: "auto",
  },
  actionButton: {
    whiteSpace: "nowrap",
    "&:hover, &:focus": {
      textDecoration: "none",
    },
  },
  secondaryAction: {
    whiteSpace: "nowrap",
    margin: theme.spacing(0, 1),
  },
}));

export interface ToastNotificationProps
  extends Omit<SnackbarProps, "children"> {
  children: AlertProps["children"];
  /** allow only success and error cases */
  severity?: "success" | "error";
  dismissText?: string;
  secondaryAction?: {
    label: string;
    action: () => void;
  };
  reportErrorText?: string;
  onReportError?: () => void;
  showReportError?: boolean;
  AlertProps?: Omit<AlertProps, "severity">;
  SuccessIcon?: ReactElement;
  ErrorIcon?: ReactElement;
}

/**
 * Customizable wrapper component around Material UI's Snackbar & Alert components
 * that is used to display success and error messages.
 * The component will disappear on its own after a period of time, but the user may also
 * close the notification by clicking the "Dismiss" button.
 */
export const ToastNotification = ({
  children,
  severity = "success",
  dismissText = "DISMISS",
  reportErrorText = "REPORT ERROR",
  secondaryAction,
  onReportError = noop,
  showReportError = true,
  AlertProps = {},
  onClose = noop,
  // autoHideDuration only works if you have an onClose function specified that modifies the `open` condition
  autoHideDuration = 1500,
  SuccessIcon = <CheckCircleIcon />,
  ErrorIcon = <TimesCircleErrorIcon />,
  ...notificationProps
}: ToastNotificationProps) => {
  const { classes, cx } = useStyles();

  return (
    <Snackbar
      TransitionComponent={Slide}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      {...{ onClose, autoHideDuration }}
      {...notificationProps}
      className={cx(classes.snackbar, notificationProps.className)}
    >
      <Alert
        {...{ severity }}
        action={
          <>
            {severity === "error" && showReportError && (
              <TertiaryButton
                className={classes.actionButton}
                onClick={onReportError}
              >
                {reportErrorText}
              </TertiaryButton>
            )}
            <TertiaryButton
              className={classes.actionButton}
              onClick={(e: SyntheticEvent) => onClose(e, "clickaway")}
            >
              {dismissText}
            </TertiaryButton>
            {secondaryAction && (
              <SecondaryButton
                className={classes.secondaryAction}
                onClick={(e: SyntheticEvent) => {
                  onClose(e, "clickaway");
                  secondaryAction.action?.();
                }}
              >
                {secondaryAction.label}
              </SecondaryButton>
            )}
          </>
        }
        {...AlertProps}
        classes={{
          ...AlertProps.classes,
          root: cx(classes.alert, AlertProps.classes?.root),
          icon: cx(classes.icon, AlertProps.classes?.icon),
          action: cx(classes.action, AlertProps.classes?.action),
          message: cx(classes.message, AlertProps.classes?.message),
        }}
        iconMapping={{
          success: SuccessIcon,
          error: ErrorIcon,
          ...AlertProps.iconMapping,
        }}
      >
        {children}
      </Alert>
    </Snackbar>
  );
};
