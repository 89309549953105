const colorsDefinition = {
  "neutral-10-alpha": "rgba(255,255,255,0.25)", //$neutral-10, 25% opacity
  "neutral-10": "#FFFFFF",
  "neutral-20": "#F7F7F7",
  "neutral-30": "#EEEEEE",
  "neutral-40": "#DDDDDD",
  "neutral-50": "#BBBBBB",
  "neutral-60": "#767676",
  "neutral-70": "#595959",
  "neutral-80": "#444444",
  "neutral-90": "#333333",
  "neutral-100": "#121212",
  "neutral-100-alpha": " rgba(0,0,0,0.25)", //$neutral-100, 25% opacity
  "neutral-0-alpha-10": "rgba(0,0,0,0.1)",
  "neutral-90-alpha-10": "rgba(51,51,51,0.1)",
  "border-dropshadow": "rgba(255,255,255,0)", // <- a border hidden in light mode, so we can have a dropshadow

  "primary-main": "#566E9F",
  "primary-surface": "#EBF0FF",
  "primary-border": "#C1D0F0",
  "primary-hover": "#44577E",
  "primary-pressed": "#233A6A",
  "primary-focus": "rgba(70, 115, 211, 0.3)", // $primary-main, 30% opacity
  "primary-light": "#DBE4FF",

  "secondary-disabled-border": "rgba(0, 0, 0, 0.12)",
  "secondary-disabled-text": "#BBBBBB",
  "secondary-light": "#DEE2E6",
  "secondary-main": "#868E96",

  "danger-main": "#D32222",
  "danger-surface": "#FFECEC",
  "danger-border": "#F0B5B5",
  "danger-hover": "#B01C1C",
  "danger-pressed": "#6A1111",

  "warning-main": "#FFC107",
  "warning-surface": "#FFF3CD",
  "warning-border": "#FFEAAC",
  "warning-hover": "#D4A106",
  "warning-pressed": "#806104",

  "success-main": "#15A858",
  "success-surface": "#D0EEDE",
  "success-border": "#B1E2C7",
  "success-hover": "#128C49",
  "success-pressed": "#0B542C",

  "standard-blue": "#346EEE",

  "brand-cornflower-blue": "#5077C5",
  "brand-beyond-blue": "#3727D6",
  "brand-neptune": "#73BFBF",
  "brand-yellow": "#FFC107",
  "brand-adam-blue": "#2C355B",
  "brand-dark-gray": "#B3BFCC",
  "brand-light-gray": "#CAD2DD",
  "brand-white": "#F6F7FC",

  "data-viz-blue": "#005EEC",
  "data-viz-orange": "#EC7100",
  "data-viz-burgundy": "#B42B8E",
  "data-viz-yellow": "#FFDD64",
  "data-viz-tangerine": "#F5B880",

  "pastel-aqua": "#CAF6F6",
  "pastel-yellow": "#FFF2AC",
  "pastel-green": "#D0F0A8",
  "pastel-blue": "#D0E6FF",
  "pastel-orange": "#FFD8A9",
  "pastel-pink": "#FECEFF",
  "pastel-red": "#FCCECE",
  "pastel-gray": "#EEEEEE",

  // Elevation Colors
  "elevation-style-2": "rgba(0, 0, 0, 0.15)",
};

const darkColorsDefinition: typeof colorsDefinition = {
  "neutral-10-alpha": "rgba(0,0,0,0.25)", //$neutral-10, 25% opacity
  "neutral-10": "#1A1A1A",
  "neutral-20": "#1E1E1E",
  "neutral-30": "#1E1E1E",
  "neutral-40": "#333333",
  "neutral-50": "#767676",
  "neutral-60": "#BBBBBB",
  "neutral-70": "#DDDDDD", // <--- No actual color defined for this yet (this is a placeholder)
  "neutral-80": "#EEEEEE", // <--- No actual color defined for this yet (this is a placeholder)
  "neutral-90": "#FFFFFF",
  "neutral-100": "#FFFFFF",
  "neutral-100-alpha": "rgba(255,255,255,0.25)", //$neutral-100, 25% opacity
  "neutral-0-alpha-10": "rgba(0,0,0,0.1)",
  "neutral-90-alpha-10": "rgba(51,51,51,0.1)",

  "border-dropshadow": "rgba(255,255,255,0.25)",

  "primary-main": "#658ADA",
  "primary-surface": "#1E1E1E",
  "primary-border": "#C1D0F0",
  "primary-hover": "#3A60B0",
  "primary-pressed": "#233A6A",
  "primary-focus": "rgba(255,255,255,0.25)",
  "primary-light": "#B3BFCC",

  "secondary-disabled-border": "rgba(255, 255, 255, 0.25)",
  "secondary-disabled-text": "#767676",
  "secondary-light": "#DEE2E6",
  "secondary-main": "#868E96",

  "danger-main": "#E99090",
  "danger-surface": "#E99090",
  "danger-border": "#F0B5B5",
  "danger-hover": "#E99090",
  "danger-pressed": "#6A1111",

  "warning-main": "#FFC107",
  "warning-surface": "#FFF3CD",
  "warning-border": "#FFEAAC",
  "warning-hover": "#D4A106",
  "warning-pressed": "#806104",

  "success-main": "#8AD4AC",
  "success-surface": "#8AD4AC",
  "success-border": "#B1E2C7",
  "success-hover": "#128C49",
  "success-pressed": "#0B542C",

  "standard-blue": "#5686F1",

  "brand-cornflower-blue": "#5077C5",
  "brand-beyond-blue": "#3727D6",
  "brand-neptune": "#73BFBF",
  "brand-yellow": "#FFC107",
  "brand-adam-blue": "#2C355B",
  "brand-dark-gray": "#B3BFCC",
  "brand-light-gray": "#CAD2DD",
  "brand-white": "#767676",

  "data-viz-blue": "#005EEC",
  "data-viz-orange": "#EC7100",
  "data-viz-burgundy": "#B42B8E",
  "data-viz-yellow": "#FFDD64",
  "data-viz-tangerine": "#FFB084",

  "pastel-aqua": "#CAF6F6",
  "pastel-yellow": "##FFF2AC",
  "pastel-green": "#D0F0A8",
  "pastel-blue": "#D0E6FF",
  "pastel-orange": "#FFD8A9",
  "pastel-pink": "#FECEFF",
  "pastel-red": "#FCCECE",
  "pastel-gray": "#EEEEEE",

  // Elevation Colors
  "elevation-style-2": "rgba(255, 255, 255, 0.15)",
};

export type Colors = typeof colorsDefinition;
export type colorKey = keyof typeof colorsDefinition;

export const colors: Colors = colorsDefinition;
export const darkColors: Colors = darkColorsDefinition;
